<template>
  <div class="read-member-history-file">
    reading
  </div>
</template>

<script>
import { GetMemberHistoryFile } from '@/api/memberHistory'
import { useAsyncState } from '@vueuse/core'
import { onMounted, getCurrentInstance } from 'vue'
export default {
  name: 'ReadMemberHistoryFile',
  props: {
    id: { type: String, required: true },
    shopId: { type: String, required: true },

    downloadKey: { type: String, required: true },
  },
  setup (props, context) {
    const req = useAsyncState(GetMemberHistoryFile, {}, { immediate: false })

    const messageError = (error) => {
      const vm = getCurrentInstance().proxy
      if (!error) return
      vm.$message.error(error)
    }

    const getFile = async () => {
      const res = await req.execute(0, { ...props, fileFreeDownloadKey: props.downloadKey })
      if (req.error.value) {
        messageError(req.error.value || req.error.value.message)
        return
      }
      window.location.replace(res.url)
    }

    onMounted(() => {
      getFile()
    })
    return {
      getFile,
    }
  },
}
</script>

<style lang="scss">
.read-member-history-file {
}
</style>
